import React from "react"
import { youtubeVideoContainer } from "./youtubeVideo.module.scss"

const YoutubeVideo = ({ videoId, videoTitle }) => (
    <div className={youtubeVideoContainer}>
        <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${videoId}`}
            title={videoTitle}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen={true}>
        </iframe>
    </div>
)
export default YoutubeVideo