import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from 'gatsby';
import ReactModal from 'react-modal';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';


import Layout from "../components/layout"
import YoutubeVideo from "../components/youtubeVideo"
import Seo from "../components/seo"
import { Container, Row, Col, Button } from "react-bootstrap"
import { fileCard, fileCardFooter, titleContainer, videoButtons } from "../styles/account.module.scss"

const AccountPage = (pageData) => {

    const courseFiles = pageData.data.allContentfulCourseFile.nodes
    const courseVideos = pageData.data.allContentfulCourseVideo.nodes

    const [modalOpen, setModalOpen] = useState(false)
    const [selectedVideo, setSelectedVideo] = useState(null)
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
            console.log(authData)
        });
    }, []);

    const openVideoModal = (videoIndex) => {
        setSelectedVideo(videoIndex)
        setModalOpen(true)
    }

    const closeVideoModal = () => {
        setSelectedVideo(null)
        setModalOpen(false)
    }

    const changeVideoNumber = (num) => {
        if (num > 0 && selectedVideo + num <= courseVideos.length - 1) {
            setSelectedVideo(selectedVideo + num)
        } else if (num < 0 && selectedVideo - num >= 0) {
            setSelectedVideo(selectedVideo + num)
        }
    }

    return authState === AuthState.SignedIn && user ? (
        <Layout>
            <Seo title="My Account" />
            <section>
                <div style={{ backgroundImage: 'radial-gradient(circle at 100% 100%, #872424 0%, #9A2020 38%, #EF0E0E 82%)' }}>
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8}>

                                <div className={`${titleContainer} text-center`}>
                                    <h4 style={{ marginBottom: 8 }}>MY ACCOUNT</h4>
                                    <h1>
                                        {user && "attributes" in user ? `Welcome, ${user.attributes.given_name}` : 'Welcome'}
                                    </h1>
                                    <p>
                                        You can find all your resources below
            </p>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                    <svg width="100%" height="100" viewBox="0 0 500 120" preserveAspectRatio="none" className="background-svg" style={{ marginTop: '-4rem' }}>
                        <path d="M0,120 L0,0 Q250,80 500,0 L500,120 Z" fill="#FAFAFA" />
                    </svg>
                </div>
            </section>
            <section style={{ marginBottom: '2rem' }}>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <h3>Course Files</h3>
                                <div className="title-highlight" style={{
                                    width: '10rem',
                                    marginBottom: '3rem'
                                }}></div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {courseFiles.map((file, index) => {
                            const image = getImage(file.filePreview.gatsbyImageData)

                            return (
                                <Col sm={6} md={4} lg={3} key={index}>
                                    <a href={file.file.file.url} download target="_blank" rel="noreferrer" style={{ textDecoration: "unset", color: "unset" }}>
                                        <div className={`${fileCard} card`}>
                                            <div>
                                                <GatsbyImage image={image} alt={``} />
                                            </div>
                                            <div className={fileCardFooter}>
                                                <h4>{file.fileName} ©</h4>
                                            </div>
                                        </div>
                                    </a>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </section>

            <section>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <h3>Course Videos</h3>
                                <div className="title-highlight" style={{
                                    width: '10rem',
                                    marginBottom: '3rem'
                                }}></div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {courseVideos.map((file, index) => {
                            const image = getImage(file.videoThumbnail.gatsbyImageData)

                            return (
                                <Col sm={6} md={4} lg={3} key={index}>
                                    <div className={`${fileCard} card`} onClick={() => openVideoModal(index)}>
                                        <div>
                                            <GatsbyImage image={image} alt={``}/>
                                        </div>
                                        <div className={fileCardFooter}>
                                            <h4>{file.videoTitle} ©</h4>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </section>


            <ReactModal
                isOpen={modalOpen}
                style={{
                    content: {
                        display: 'flex',
                        alignItems: 'center',
                        background: 'unset',
                        border: 'unset',
                        inset: '0px',
                        padding: '0px'
                    }
                }}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => closeVideoModal()}>
                <Container fluid>
                    <Row noGutters={true}>
                        <Col xs={{span: 12}} lg={{ offset: 1, span: 10 }} xl={{ offset: 2, span: 8 }}>
                            {selectedVideo != null &&
                                <YoutubeVideo videoId={courseVideos[selectedVideo].videoId} videoTitle="Neuralink - Elon Musk's Brain Machine Interface"></YoutubeVideo>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{span: 12}} lg={{ offset: 1, span: 10 }} xl={{ offset: 2, span: 8 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }} className={videoButtons}>
                                <Button onClick={() => changeVideoNumber(-1)}>Previous Video</Button>
                                <Button onClick={() => closeVideoModal()}>Close Video</Button>
                                <Button onClick={() => changeVideoNumber(1)}>Next Video</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </ReactModal>
        </Layout>
    ) : (
        <AmplifyAuthenticator usernameAlias="email">
            <AmplifySignUp
                slot="sign-up"
                usernameAlias="email"
                formFields={[
                    {
                        type: "email",
                        label: "Email Address",
                        placeholder: "Email",
                        inputProps: { required: true, autocomplete: "username" },
                    },
                    {
                        type: "password",
                        label: "Password",
                        placeholder: "Password",
                        inputProps: { required: true, autocomplete: "new-password" },
                    },
                    {
                        type: "family_name",
                        label: "Surname",
                        placeholder: "Surname",
                        inputProps: { required: true, autocomplete: "family-name" },
                    },
                    {
                        type: "given_name",
                        label: "First Name",
                        placeholder: "First name",
                        inputProps: { required: true, autocomplete: "given-name" },
                    },
                ]}
            />
            <AmplifySignIn slot="sign-in" usernameAlias="email" />
        </AmplifyAuthenticator>

    )
}

export const pageQuery = graphql`{
    allContentfulCourseVideo {
      nodes {
        videoId
        videoTitle
        videoThumbnail {
          gatsbyImageData(
              aspectRatio:1.7777
              layout: FULL_WIDTH
        )
        }
        videoDescription {
          videoDescription
        }
      }
    }
    allContentfulCourseFile {
      nodes {
        file {
          file {
            url
          }
        }
        fileName
        filePreview {
          gatsbyImageData(aspectRatio:1.7777)
        }
      }
    }
  }
`

export default AccountPage;
